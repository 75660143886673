var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.params.canAccess ? _c('div', {
    staticClass: "registrationWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-xxl-7 col-xl-7 col-lg-7 col-md-8"
  }, [_c('div', {
    staticClass: "success-block text-center"
  }, [_vm._m(0), _c('h1', [_vm._v("Thank you for Registration !")]), _vm._m(1), _c('div', {
    staticClass: "success-action"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1)])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "success-image mb-4"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/rep-registration/success.svg"),
      "id": "registration_success_image"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" We have received your information and it is now up for review. "), _c('br'), _vm._v(" Please check your email for confirmation. "), _c('br'), _vm._v(" Thank you. ")]);
}]

export { render, staticRenderFns }