<template>
  <div class="registrationWrapper" v-if="$route.params.canAccess">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-8">
          <div class="success-block text-center">
            <div class="success-image mb-4">
              <img
                src="@/assets/images/rep-registration/success.svg"
                class="img-fluid"
                id="registration_success_image"
              />
            </div>
            <h1>Thank you for Registration !</h1>
            <p>
              We have received your information and it is now up for review.
              <br />
              Please check your email for confirmation. <br />
              Thank you.
            </p>
            <div class="success-action">
              <router-link to="/">Home</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "RegisterSuccess",
  methods:{
    fetchDynamicColor() {
      let userType= "A"
      let agentId=localStorage.getItem('agent_id');
      axios.get(`v2/colorcoding/get-theme-list?user_id=${agentId}&user_types=${userType}`, {
        headers: {
          'Authorization': process.env.VUE_APP_REG_API_KEY,
        }
      }).then(response => {
          this.dynamicColorList = response.data.data;
          this.dynamicColorList.forEach((variable, index) => {
              const colorValue = response.data.data[index].value;
          if(variable.key==="VUE_APP_REGISTRATION_LOGO"){
            let imgLogoSrc= variable.value;
            if(!imgLogoSrc){
              imgLogoSrc="https://rep-dashboard-theme.s3.us-east-2.amazonaws.com/theme-assets/nueralogo.png"
            }
            document.getElementById("registration_logo").src=imgLogoSrc;
          }
          if(variable.key==="VUE_APP_REGISTRATION_SUCCESS_IMAGE"){
            let imgSrc= variable.value;
            if(!imgSrc){
              imgSrc= require("@/assets/images/rep-registration/success.svg")
            }
            document.getElementById("registration_success_image").src=imgSrc;
          }
        if (variable.key) {
            document.documentElement.style.setProperty(`--${variable.key}`, colorValue);
        }
    })
  }).catch(error => {
        console.error("Error fetching dynamic color", error);
      });
    },
  },
  
  created() {
    this.fetchDynamicColor()
    let canAccessPage = this.$route.params.canAccess;
    if (!canAccessPage) {
      this.$router.push({
        name: "Dashboard",
      });
    }
  },
};
</script>
<style src="@/assets/css/rep-registration.css"></style>